import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject, Subscription } from 'rxjs';
import { filter, finalize, map, take } from 'rxjs/operators';

import {
  BudgetPreferences,
  BudgetsSetting,
  Company,
  ConvertToKeyValue,
  DealsWrapperCustomPropertySort,
  DealsWrapperPropertySort,
  DynamicPropertyRef,
  Feature,
  FeatureUser,
  IBudgetsSetting,
  ICustomProperty,
  IExpense,
  IUser,
  PaginateResponse,
  PaginateSort,
} from '@tacliatech/types';

import { TranslateService } from '@ngx-translate/core';

import {
  FilterItem,
  FilterItems,
} from '@web-frontend/shared/components/filter';

import { TableSortEvent } from '@web-frontend/shared/components/table';

import {
  AssetService,
  DealService,
  DynamicPropertyService,
  ProjectService,
  StorageService,
} from '@web-frontend/shared/services';

import { DatePipe } from '@angular/common';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { CreateExpenseService } from '@web-frontend/shared/components/create-expense';
import { DeleteBySelectionModalComponent } from '@web-frontend/shared/components/delete-by-selection-modal/delete-by-selection-modal.component';
import { FilterComponent } from '@web-frontend/shared/components/filter/filter.component';
import { RmFilter } from '@web-frontend/shared/components/globals/rm-filter';
import { ModalMobileInfoComponent } from '@web-frontend/shared/components/modal-mobile-info';
import { VideoService } from '@web-frontend/shared/components/modal-video/video.service';
import { TableComponent } from '@web-frontend/shared/components/table/table.component';
import { FileWriteService } from '@web-frontend/shared/helpers/file-write';
import { AuthService } from '@web-frontend/shared/services/auth/auth.service';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { CategoryExpensesService } from '@web-frontend/shared/services/category-expenses';
import { CompanyModuleService } from '@web-frontend/shared/services/company/company-module.service';
import {
  ExpenseSearchService,
  ExpenseService,
} from '@web-frontend/shared/services/expense';
import { TutorialService } from '@web-frontend/shared/services/tutorial';
import { SandboxService } from '@web-frontend/shared/services/sandbox/sandbox.service';
import { ScrollService } from '@web-frontend/shared/services/scroll';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { TypeExpenseService } from '@web-frontend/shared/services/type-expense';
import {
  CompareObjects,
  RemoveEmpty,
  getCustomValue,
  getDateStrWithoutUTC,
} from '@web-frontend/shared/utils';
import { Workbook } from 'exceljs';
import { round } from 'lodash';
import {
  SOURCE_MOBILE_OPTIONS,
  SOURCE_WEB,
  SOURCE_WEB_OPTIONS,
} from './expenses.const';
import { INIT_ACTIVITY_SEARCH_PARAMS } from './expenses.types';
import { DownloadType } from '@web-frontend/shared/components/download-btn/download-btn.component';
import { DownloadBtnService } from '@web-frontend/shared/components/download-btn/download-btn.service';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
import { FileUploadService } from '@web-frontend/shared/helpers/file-upload';
import { CreateDealService } from '@web-frontend/shared/components/create-deal';

@Component({
  selector: 'roma-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpensesComponent implements OnInit, OnDestroy, AfterViewInit {
  tableMarginTop = '0px';
  distanceToTop = 0;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.tableMarginTop = '0px';
    this.calcTableDistance();
  }

  @HostBinding('class.roma-page')
  hostClass = true;

  @ViewChild(FilterComponent)
  filterComponent: FilterComponent;

  @ViewChild(TableComponent)
  tableComponent: TableComponent;

  @Input()
  showAddBtn = false;

  @Input()
  showTitle = true;

  @Input()
  showFilter = true;

  @Input()
  showTotal = false;

  @Output()
  modalIsLoadingEvent = new EventEmitter<boolean>();

  deal: string = null;
  totalExpenses = 0;
  subtotalExpenses = 0;

  @Input()
  set dealId(value: string) {
    this.deal = value;
    this.searchParams = {
      ...this.searchParams,
      'deals[]': [this.deal],
    };

    this.requestExpenses({ loadingGlobal: true });
  }

  columns = [];
  _showEmptyState = false;
  searchParamsObj = {};

  expenses: MatTableDataSource<IExpense>;
  resultSearch: PaginateResponse<IExpense[]>;
  expenseMap = new Map<string, IExpense>();

  settings: BudgetPreferences = null;
  defaultSettings = new BudgetsSetting();
  defaultPreferences = this.defaultSettings.budgetPreferences;

  featureRef = Feature;
  featureRefUser = FeatureUser;

  isDataLoaded = false;
  isLoading = false;
  isLoadingPaginate = false;
  modalIsLoading = false;
  allowShowButtonDownTakeMore = false;

  authors: FilterItems = [];
  owners: FilterItems = [];
  assets: FilterItems = [];
  deals: FilterItems = [];
  totalPages: any[] = [];

  customProperties: ICustomProperty[] = [];
  viewTypeActive: 'ROW' | 'PIPELINE' | 'CALENDAR' = 'ROW';

  typeExpenses = this.typeExpenseService.findAll();
  statusExpenses = this.typeExpenseService.findAllStatusExpenses();

  user;

  sourceSearch = SOURCE_WEB;
  sourceOptions!: RmFilter.Filter;

  private sub$ = new Subscription();
  private storeSearchParams = INIT_ACTIVITY_SEARCH_PARAMS;
  private busSearchParamsChanges = new Subject();

  get showEmptyState(): boolean {
    return this._showEmptyState && !this.isLoading;
  }

  searchParamsHasChange$ = this.busSearchParamsChanges
    .asObservable()
    .pipe(map((res) => !CompareObjects(INIT_ACTIVITY_SEARCH_PARAMS, res)));

  resize$ = this.sandBoxService.screenBusChannel$;

  company_date_format = this.budgetService.date_format
    ? this.budgetService.date_format
    : 'dd/MM/yyyy';

  scrollDown$ = this.scrollService.scrollDown$;

  academyLink = '';

  constructor(
    private expenseService: ExpenseService,
    private expenseSearchService: ExpenseSearchService,
    private createExpenseService: CreateExpenseService,
    private typeExpenseService: TypeExpenseService,
    private changeDetectionRef: ChangeDetectorRef,
    private i18n: TranslateService,
    private authService: AuthService,
    private assetService: AssetService,
    private activatedRouter: ActivatedRoute,
    private dynamicPropertyService: DynamicPropertyService,
    private dealService: DealService,
    private video: VideoService,
    private fileWrite: FileWriteService,
    private datePipe: DatePipe,
    private budgetService: BudgetService,
    private sandBoxService: SandboxService,
    private categoryExpensesService: CategoryExpensesService,
    private companyModuleService: CompanyModuleService,
    public dialog: MatDialog,
    private projectService: ProjectService,
    private toastService: ToastService,
    public tutorialService: TutorialService,
    private scrollService: ScrollService,
    private readonly downloadBtnService: DownloadBtnService,
    private readonly router: Router,
    private readonly analyticsService: AnalyticsService,
    private readonly createDealService: CreateDealService
  ) {}

  get searchParams() {
    return this.storeSearchParams;
  }

  set searchParams(value) {
    this.busSearchParamsChanges.next(value);
    this.storeSearchParams = value;
  }

  async ngOnInit() {
    this.getModuleResources();
    this.resolveColumnsView();
    this.getSettings();
    this.watchRefreshList();
    this.watchRouteParams();
    this.resolveRouteParams();
    await this.resolveInputParams();
    this.requestAuthors();
    this.authService.user$.subscribe((res) => (this.user = res));
    this.requestDynamicProperties();
    if (!this.deal) {
      this.requestExpenses({ loadingGlobal: true });
    }
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  getLang() {
    return this.i18n.currentLang;
  }

  ngAfterViewInit() {
    this.resolveInputFilterParams();
  }

  private resolveColumnsView() {
    this.sub$.add(
      this.companyModuleService.idActiveModules$.subscribe((res) => {
        if (res.length) {
          let columns = [
            'select',
            'dateTime',
            'number',
            'typeExpense',
            'dueDate',
            'expenseVendorObj',
            'ownersObj',
            'dealObj',
            'expenseProjectObj',
            'expenseCategoryObj',
            'statusExpense',
            'subtotal',
            'total',
          ];
          if (this.showTitle == false) {
            columns = [
              'dateTime',
              'number',
              'typeExpense',
              'dueDate',
              'expenseVendorObj',
              'ownersObj',
              'dealObj',
              'expenseProjectObj',
              'expenseCategoryObj',
              'statusExpense',
              'subtotal',
              'total',
            ];
          }

          this.columns = Company.FilterColumnReports({
            columnsReports: columns,
            nameColumnsReport: ['deal', 'expenseVendorObj'],
            idModules: [
              Company.IdModule.Deals,
              Company.IdModule.InternalVendors,
            ],
            idActivesModules: res,
          });

          this.draw();
        }
      })
    );
  }

  hasQuerySearch() {
    return (
      this.searchParams.author ||
      this.searchParams['deals[]'] ||
      this.searchParams.owner ||
      this.searchParams.keyword
    );
  }

  changeViewType(evt: MatButtonToggleChange) {
    const { value } = evt;

    this.viewTypeActive = value;

    this.requestExpenses({ loadingGlobal: true });
    this.draw();
  }

  changeCustomProperties(customProperties: { [key: string]: any }) {
    this.searchParams = {
      ...this.searchParams,
      customProperties: JSON.stringify(customProperties),
    };

    this.clear();

    this.requestExpenses({ loadingGlobal: true });
  }

  private resolveInputFilterParams() {
    if (this.searchParams?.keyword) {
      try {
        this.filterComponent.input.nativeElement.value = this.searchParams?.keyword;
        this.draw();
      } catch (err) {}
    }

    if (this.dealId) {
      this.searchParams = {
        ...this.searchParams,
        'deals[]': [this.dealId],
      };
    }
  }

  private watchRouteParams() {
    this.sub$.add(
      this.activatedRouter.queryParams
        .pipe(filter((res) => Object.keys(res)?.length > 0))
        .subscribe(() => {
          this.resolveRouteParams();
          this.clear();
          this.resolveInputFilterParams();
          this.requestExpenses({ loadingGlobal: true });
        })
    );
  }

  private resolveRouteParams() {
    const paramsMap = this.activatedRouter.snapshot.queryParamMap;

    const id = paramsMap.get('id');

    if (id) {
      this.searchParams = {
        ...this.searchParams,
        keyword: id,
      };

      this.draw();
    }
  }

  private watchRefreshList() {
    this.sub$.add(
      this.createExpenseService.refreshList$
        .pipe(filter((res) => res))
        .subscribe(() => {
          this.clear();

          this.searchParams = {
            ...this.searchParams,
            page: 1,
          };

          this.requestExpenses({ loadingGlobal: true });
        })
    );
  }

  private requestDynamicProperties() {
    this.sub$.add(
      this.dynamicPropertyService
        .findDynamicProperties(
          StorageService.CompanyId,
          DynamicPropertyRef.Expenses
        )
        .subscribe((res) => {
          this.customProperties = res;
          this.draw();

          this.sub$.add(
            this.sandBoxService.screenBusChannel$.subscribe((channel) => {
              const isWeb = !channel.payload.mobile;

              const source = isWeb ? SOURCE_WEB_OPTIONS : SOURCE_MOBILE_OPTIONS;

              this.sourceOptions = source({
                projectService: this.projectService,
                categoryExpensesService: this.categoryExpensesService,
                companyModuleService: this.companyModuleService,
                dealService: this.dealService,
                assetService: this.assetService,
                customProperties: res,
                i18n: this.i18n,
              });

              this.draw();
            })
          );
        })
    );
  }

  private requestExpenses({
    loadingGlobal = false,
    loadingPagination = false,
  }) {
    if (loadingGlobal) {
      this.isLoading = true;
      this.draw();
    }

    if (loadingPagination) {
      this.isLoadingPaginate = true;
      this.draw();
    }

    const query = RemoveEmpty(this.searchParams);

    this.searchParams;
    this.sub$.add(
      this.expenseSearchService
        .search({
          ...query,
        })
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.isLoadingPaginate = false;
            this.draw();
          })
        )
        .subscribe((res) => {
          this.setExpenses(res);
          this._showEmptyState =
            Object.keys(this.searchParamsObj).length === 0 &&
            res.docs.length === 0;
        })
    );
  }

  nextPage() {
    this.searchParams = {
      ...this.searchParams,
      page: this.searchParams.page + 1,
    };

    this.requestExpenses({ loadingGlobal: true, loadingPagination: true });
  }

  goToPage(page) {
    this.searchParams = {
      ...this.searchParams,
      page: page,
    };

    this.requestExpenses({ loadingGlobal: true, loadingPagination: true });
  }

  previousPage() {
    this.searchParams = {
      ...this.searchParams,
      page: this.searchParams.page - 1,
    };

    this.requestExpenses({ loadingGlobal: true, loadingPagination: true });
  }

  changeOwner(evt: FilterItem) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      owner: evt.value,
    };

    this.clear();
    this.requestExpenses({ loadingGlobal: true });
  }

  changeAuthor(evt: FilterItems) {
    const authors = evt.map((author) => author.value);

    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'users[]': authors,
    };
    this.clear();
    this.requestExpenses({ loadingGlobal: true });
  }

  changeDeal(evt: FilterItems) {
    const deals = evt.map((deal) => deal.value);

    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'deals[]': deals,
    };
    this.clear();
    this.requestExpenses({ loadingGlobal: true });
  }

  changeCategoryExpense(evt: FilterItems) {
    const assets = evt.map((type) => type.value);

    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'categories[]': assets,
    };

    this.clear();
    this.requestExpenses({ loadingGlobal: true });
  }

  changeInput(keyword: string) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      keyword: keyword ? keyword : null,
    };

    this.clear();

    this.requestExpenses({ loadingGlobal: true });
  }

  changeNotified(evt: boolean) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      takeNotified: evt,
    };

    this.clear();
    this.requestExpenses({ loadingGlobal: true });
  }

  changeDeleted(evt: boolean) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      takeDeleted: evt,
    };

    this.clear();
    this.requestExpenses({ loadingGlobal: true });
  }

  changeToday(evt: boolean) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      takeToday: evt,
    };

    this.clear();
    this.requestExpenses({ loadingGlobal: true });
  }

  changeDate(type: 'START' | 'END', value: Date) {
    let date;
    if (value) {
      date = getDateStrWithoutUTC(value);
    } else {
      date = '';
    }
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      [type === 'START' ? 'from' : 'to']: date,
    };

    this.clear();
    this.requestExpenses({ loadingGlobal: true });
  }

  addExpense() {
    this.analyticsService.trackEvent({
      sources: ['amplitude', 'braze'],
      eventName: AmplitudeEvents.expense_start,
    });

    const fromDealId = this.dealId || this.deal;
    if (fromDealId) this.createDealService.close();
    const queryParams = { fromDealId };
    this.router.navigate([`/admin/expense/create`], { queryParams });
  }

  sortChange(evt: TableSortEvent) {
    evt.active = evt.active === 'statusExpense' ? 'status' : evt.active;
    let resCustomProperty = DealsWrapperCustomPropertySort(
      this.customProperties,
      evt.active
    );

    if (!resCustomProperty) {
      resCustomProperty = DealsWrapperPropertySort(evt.active);
    }

    this.clearSortOptions();

    this.searchParams = {
      ...this.searchParams,
      page: 1,
    };

    if (evt.direction) {
      this.searchParams = {
        ...this.searchParams,
        [`sortOptions[${resCustomProperty}]`]: evt.direction,
      };
    } else {
      this.searchParams = {
        ...this.searchParams,
        'sortOptions[created_at]': PaginateSort.DESC,
      };
    }
    this.clear();
    this.requestExpenses({ loadingGlobal: true, loadingPagination: false });
  }

  editExpense(value: IExpense) {
    this.entriesForDelete = [];
    this.deleteMessage = false;
    const fromDealId = this.dealId || this.deal;
    if (fromDealId) this.createDealService.close();
    const queryParams = { fromDealId };
    this.router.navigate([`/admin/expense/update/${value._id}`], {
      queryParams,
    });
  }

  requestOpenExpense(id: string) {
    this.sub$.add(
      this.expenseSearchService.search({ 'ids[]': [id] }).subscribe((res) => {
        const [expense] = res.docs;

        if (expense) {
          this.editExpense(expense);
        }
      })
    );
  }

  markExpense(value: IExpense) {
    this.sub$.add(
      this.expenseService.markDoneOne(value._id).subscribe(() => {
        this.requestExpenses({ loadingGlobal: true });
        this.expenseMap.delete(value._id);
        this.source = Array.from(this.expenseMap.values());
        this.toastService.show({
          text: this.i18n.instant('expense.done'),
          type: 'success',
          msDuration: 4000,
        });
      })
    );
  }

  removeExpense(value: IExpense) {
    this.sub$.add(
      this.expenseService.removeOne(value._id).subscribe(() => {
        this.expenseMap.delete(value._id);
        this.source = Array.from(this.expenseMap.values());
        this.toastService.show({
          text: this.i18n.instant('expense.delete'),
          type: 'success',
          msDuration: 4000,
        });
        this.requestExpenses({ loadingGlobal: true });
        this.analyticsService.trackEvent({
          sources: ['amplitude'],
          eventName: AmplitudeEvents.expense_list_delete,
        });
      })
    );
  }

  takeMore() {
    this.searchParams = {
      ...this.searchParams,
      page: this.searchParams.page + 1,
    };

    this.requestExpenses({ loadingPagination: true });
  }

  private parseFiles(files?: string[]): string[] {
    return (files || []).map((file) => {
      try {
        new URL(file);
        return FileUploadService.splitUrl(file);
      } catch (error) {
        return file;
      }
    });
  }

  private setExpenses(response: PaginateResponse<IExpense[]>) {
    this.clear();
    this.resultSearch = response;
    this.totalExpenses = 0;
    this.subtotalExpenses = 0;
    this.totalPages = [];
    let i;

    for (i = 1; i <= this.resultSearch.countPages; i++) {
      this.totalPages.push({
        page: i,
      });
    }

    for (const expense of response.docs) {
      this.totalExpenses = this.totalExpenses + expense.total;
      this.subtotalExpenses = this.subtotalExpenses + expense.subtotal;
      Object.assign(expense, { imgs: this.parseFiles(expense.imgs) });
      this.expenseMap.set(expense?._id, expense);
    }

    if (response.hasNextPage) {
      this.allowShowButtonDownTakeMore = true;
    }

    this.source = Array.from(this.expenseMap.values());
    this.calcTableDistance();
    this.resetTableMarginTop();
  }

  private set source(expenses: IExpense[]) {
    this.expenses = new MatTableDataSource(expenses);
    this.draw();
  }

  private requestAuthors() {
    this.sub$.add(
      this.assetService.findUsersByAssetsShared().subscribe((res) => {
        this.authors = ConvertToKeyValue(res, 'name', '_id');
        this.draw();
      })
    );
  }

  private requestDeals() {
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Deals)))
        .subscribe(() => {
          this.dealService.deals$.subscribe((resp) => {
            if (resp) {
              this.deals = ConvertToKeyValue(resp, 'name', '_id');
            }
            this.draw();
          });
        })
    );
  }

  private requestOwners() {
    const id = StorageService.UserId;

    this.sub$.add(
      this.expenseService.findOwners(id).subscribe((res) => {
        this.owners = ConvertToKeyValue(res, 'name', '_id');
        this.draw();
      })
    );
  }

  private requestCategoryExpenses() {
    this.sub$.add(
      this.categoryExpensesService.findAll().subscribe((res) => {
        this.assets = ConvertToKeyValue(res, 'name', '_id');
        this.draw();
      })
    );
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  private clearSortOptions() {
    const searchParams = {
      ...this.searchParams,
    };

    for (const prop in searchParams) {
      if (searchParams[prop]) {
        if (prop.match(/sortOptions/)) {
          delete searchParams[prop];
        }
      }
    }

    this.searchParams = searchParams;
  }

  private clear() {
    this.expenseMap.clear();

    this.source = [];
  }

  private resolveInputParams() {
    return new Promise((res) => {
      this.searchParams = {
        ...this.searchParams,
        deal: this.dealId,
      };

      res(null);
    });
  }

  openVideo(type: string) {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.expense_TutorialVideo,
    });
    this.video.open(type);
  }

  createHeaderExcel() {
    const not_available = this.i18n.instant('generateDocument.notAvailable');
    const service = this.i18n.instant('sidebar.services1');

    const lang = this.getLang();
    let header = [
      'Fecha',
      'Número',
      'Tipo',
      'Vencimiento',
      'Proveedor',
      'Usuarios',
      service,
      'Proyecto',
      'Categoría',
      'Subtotal',
      'Total',
      'Estado',
    ];

    if (lang === 'en') {
      header = [
        'Date',
        'Number',
        'Type',
        'Expiration',
        'Supplier',
        'User',
        service,
        'Project',
        'Category',
        'Subtotal',
        'Total',
        'State',
      ];
    }

    return { not_available, header };
  }

  downloadExcel() {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.expense_excelDownload,
    });
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Expenses');

    // eslint-disable-next-line prefer-const
    let { not_available, header } = this.createHeaderExcel();

    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(take(1))
        .subscribe((res) => {
          if (res.length) {
            header = Company.FilterColumnReports({
              columnsReports: header,
              nameColumnsReport: ['Proveedor', 'Servicio'],
              idModules: [
                Company.IdModule.InternalVendors,
                Company.IdModule.Deals,
              ],
              idActivesModules: res,
            });

            const customShow = JSON.parse(
              StorageService.CustomProperty('EXPENSE_MODULE')
            ) as ICustomProperty[];

            if (customShow?.length > 0) {
              for (const param of customShow) {
                header.push(param.name);
              }
            }
            const headerRow = worksheet.addRow(header);
            headerRow.font = {
              color: { argb: '003c48ec' },
              bold: true,
              size: 12,
            };
            headerRow.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            };

            this.expenseSearchService
              .search({
                ...RemoveEmpty(this.searchParams),
                applyPaginate: false,
                download: true,
              })
              .subscribe((resp) => {
                for (const x1 of resp.docs) {
                  const temp = [];
                  temp.push(
                    x1.dateTime
                      ? this.datePipe.transform(
                          x1.dateTime.toString().replace('Z', ''),
                          this.company_date_format
                        )
                      : not_available
                  );
                  temp.push(x1.number ? x1.number : not_available);
                  temp.push(
                    x1.type ? this.getTypeExpense(x1.type) : not_available
                  );
                  temp.push(
                    x1.dueDate
                      ? this.datePipe.transform(
                          x1.dueDate.toString().replace('Z', ''),
                          this.company_date_format
                        )
                      : not_available
                  );
                  if (res.includes(Company.IdModule.InternalVendors)) {
                    temp.push(
                      x1?.vendorObj ? x1?.vendorObj?.name : not_available
                    );
                  }
                  temp.push(
                    x1?.users
                      ? this.getUsers(x1.users).toString()
                      : not_available
                  );

                  if (res.includes(Company.IdModule.Deals)) {
                    temp.push(x1.dealObj ? x1.dealObj.name : not_available);
                  }
                  temp.push(x1.projectObj ? x1.projectObj.name : not_available);
                  temp.push(
                    x1.categoryObj ? x1.categoryObj.name : not_available
                  );
                  temp.push(round(x1.subtotal, 2));
                  temp.push(round(x1.total, 2));
                  temp.push(
                    x1.status ? this.getStatusExpense(x1.status) : not_available
                  );

                  if (customShow?.length > 0) {
                    for (const param of customShow) {
                      temp.push(getCustomValue(x1, param.name));
                    }
                  }

                  const row = worksheet.addRow(temp);
                  row.alignment = {
                    vertical: 'middle',
                    horizontal: 'center',
                  };
                }

                worksheet.columns.forEach(function (column) {
                  column.width = 27;
                });

                //add data and file name and download
                workbook.xlsx.writeBuffer().then(async (data) => {
                  const blob = new Blob([data], {
                    type:
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  });

                  this.fileWrite
                    .writeFile({
                      path: `expenses-${this.datePipe.transform(
                        new Date(),
                        'dd-MM-yyyy'
                      )}.xlsx`,
                      blob,
                    })
                    .then(() => {
                      this.downloadBtnService.downloadedSuccessfully();
                    });
                });
              });
          }
        })
    );
  }

  getTypeExpense(id: number) {
    const expense = this.typeExpenses.find(function (ex) {
      return ex.id == id;
    });
    return this.i18n.instant(expense.translate);
  }

  getStatusExpense(id: number) {
    const expense = this.statusExpenses.find(function (ex) {
      return ex.id == id;
    });
    return this.i18n.instant(expense.translate);
  }

  getUsers(users: IUser[]): string[] {
    const result = [];
    const userObject = this.authors.filter((a) => users.indexOf(a.value) > -1);
    for (const v of userObject) {
      result.push(' ' + v.key);
    }
    return result;
  }

  getSettings() {
    const id = StorageService.CompanyId;
    this.budgetService
      .findSettingsByCompany(id)
      .subscribe((resp: IBudgetsSetting) => {
        if (resp) {
          this.settings = resp.budgetPreferences;
        } else {
          this.settings = this.defaultPreferences;
        }

        this.draw();
      });
  }

  applyFilters(evt: {
    categories: Array<{ value: string }>;
    users: Array<{ value: string }>;
    deals: Array<{ value: string }>;
    startDate: string;
    endDate: string;
    takeDeleted: boolean;
    customProperties: { [key: string]: any };
  }) {
    const categories = evt?.categories?.length
      ? evt.categories.map((type) => type.value)
      : [];
    const users = evt?.users?.length ? evt.users.map((type) => type.value) : [];
    const deals = evt?.deals?.length ? evt.deals.map((type) => type.value) : [];
    const takeDeleted = evt.takeDeleted;
    const startDate = evt.startDate;
    const endDate = evt.endDate;

    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'categories[]': categories,
      'users[]': users,
      'deals[]': deals,
      ['from']: startDate,
      ['to']: endDate,
      takeDeleted: takeDeleted,
      customProperties: JSON.stringify(evt.customProperties),
    };

    this.clear();

    this.requestExpenses({ loadingGlobal: true });
  }

  calcTableDistance() {
    setTimeout(() => {
      const containerTable = document.getElementById('containerTable');
      this.distanceToTop = containerTable?.getBoundingClientRect().top - 74;
    }, 1000);
  }

  checkScroll(e) {
    if (e.target.scrollHeight > 1080) {
      if (e.target.scrollTop <= this.distanceToTop) {
        this.tableMarginTop = '-' + e.target.scrollTop + 'px';
      } else {
        this.tableMarginTop = '-' + this.distanceToTop + 'px';
      }
    }
  }

  private resetTableMarginTop() {
    this.tableMarginTop = '0px';
  }
  /**********************************SELECTION********************************* */
  entriesForDelete!: any[];
  deleteMessage = false;

  deleteMany(evt: any[]) {
    this.entriesForDelete = evt;
    if (evt.length > 0) {
      this.deleteMessage = true;
    } else {
      this.deleteMessage = false;
    }
  }

  unselectItems() {
    this.entriesForDelete = [];
    this.deleteMessage = false;
    this.tableComponent.clearChecks();
  }

  openDeleteManyDialog(): void {
    const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
      panelClass: 'delete-by-selection-modal',
      data: { isPlural: this.entriesForDelete?.length > 1 },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'EXECUTE') {
        this.deleteSelection(this.entriesForDelete);
      }
    });
  }

  deleteSelection(evt: any[]): void {
    const ids = evt.map((entry) => entry._id);
    this.sub$.add(
      this.expenseService
        .deleteMany({
          ids: ids,
        })
        .pipe(
          finalize(() => {
            this.deleteMessage = false;
            this.entriesForDelete = [];
          })
        )
        .subscribe(() => {
          this.toastService.show({
            text: 'general.deleteManyCorrect',
            type: 'error',
            msDuration: 4000,
            icon: 'assets/icons/kanban/delete.svg',
            class: '',
          });
          this.analyticsService.trackEvent({
            sources: ['amplitude'],
            eventName: AmplitudeEvents.expense_list_delete,
          });
          this.requestExpenses({ loadingGlobal: true });
        })
    );
  }

  async downloadExcelSelection() {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.expense_list_select_excelDownload,
    });
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Expenses');

    // eslint-disable-next-line prefer-const
    let { header, not_available } = this.createHeaderExcel();

    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(take(1))
        .subscribe((res) => {
          if (res.length) {
            header = Company.FilterColumnReports({
              columnsReports: header,
              nameColumnsReport: ['Proveedor', 'Servicio'],
              idModules: [
                Company.IdModule.InternalVendors,
                Company.IdModule.Deals,
              ],
              idActivesModules: res,
            });

            const customShow = JSON.parse(
              StorageService.CustomProperty('EXPENSE_MODULE')
            ) as ICustomProperty[];

            if (customShow?.length > 0) {
              for (const param of customShow) {
                header.push(param.name);
              }
            }
            const headerRow = worksheet.addRow(header);
            headerRow.font = {
              color: { argb: '003c48ec' },
              bold: true,
              size: 12,
            };
            headerRow.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            };

            for (const x1 of this.entriesForDelete) {
              const temp = [];
              temp.push(
                x1.dateTime
                  ? this.datePipe.transform(
                      x1.dateTime.toString().replace('Z', ''),
                      this.company_date_format
                    )
                  : not_available
              );
              temp.push(x1.number ? x1.number : not_available);
              temp.push(x1.type ? this.getTypeExpense(x1.type) : not_available);
              temp.push(
                x1.dueDate
                  ? this.datePipe.transform(
                      x1.dueDate.toString().replace('Z', ''),
                      this.company_date_format
                    )
                  : not_available
              );
              if (res.includes(Company.IdModule.InternalVendors)) {
                temp.push(x1?.vendorObj ? x1?.vendorObj?.name : not_available);
              }
              temp.push(
                x1?.users ? this.getUsers(x1.users).toString() : not_available
              );

              if (res.includes(Company.IdModule.Deals)) {
                temp.push(x1.dealObj ? x1.dealObj.name : not_available);
              }
              temp.push(x1.projectObj ? x1.projectObj.name : not_available);
              temp.push(x1.categoryObj ? x1.categoryObj.name : not_available);
              temp.push(round(x1.subtotal, 2));
              temp.push(round(x1.total, 2));
              temp.push(
                x1.status ? this.getStatusExpense(x1.status) : not_available
              );

              if (customShow?.length > 0) {
                for (const param of customShow) {
                  temp.push(getCustomValue(x1, param.name));
                }
              }

              const row = worksheet.addRow(temp);
              row.alignment = {
                vertical: 'middle',
                horizontal: 'center',
              };
            }

            worksheet.columns.forEach(function (column) {
              column.width = 27;
            });

            //add data and file name and download
            workbook.xlsx.writeBuffer().then(async (data) => {
              const blob = new Blob([data], {
                type:
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });

              this.fileWrite
                .writeFile({
                  path: `expenses-${this.datePipe.transform(
                    new Date(),
                    'dd-MM-yyyy'
                  )}.xlsx`,
                  blob,
                })
                .then(() => {
                  // this.toastService.show({
                  //   text: this.i18n.instant('general.savedFile'),
                  //   type: 'success',
                  //   msDuration: 4000,
                  // });
                  this.downloadBtnService.downloadedSuccessfully();
                });
            });
          }
        })
    );
  }

  changeFilters(evt: RmFilter.Changes) {
    if (evt.type === 'CHANGE' || evt.type === 'INPUT_CHANGE') {
      const { queryParams } = evt;
      this.searchParamsObj = { ...queryParams };

      if (
        JSON.stringify(queryParams) === JSON.stringify({}) &&
        evt.type === 'INPUT_CHANGE'
      ) {
        delete this.searchParams.keyword;
      }

      if (
        JSON.stringify(queryParams) === JSON.stringify({}) &&
        evt.type === 'CHANGE'
      ) {
        this.searchParams = { ...INIT_ACTIVITY_SEARCH_PARAMS };
      }

      this.searchParams = {
        ...INIT_ACTIVITY_SEARCH_PARAMS,
        ...this.searchParams,
        ...queryParams,
      };

      if (queryParams?.customProperties) {
        this.searchParams = {
          ...this.searchParams,
          customProperties: JSON.stringify(queryParams.customProperties),
        };
      }

      this.requestExpenses({ loadingGlobal: true });
    }
  }

  restore() {
    const ids = this.entriesForDelete.map((el) => el?._id);

    this.sub$.add(
      this.expenseService.restoreMany({ ids: ids }).subscribe(() => {
        this.toastService.show({
          text: this.i18n
            .instant('general.restoreMany')
            .replace('{qtty}', ids.length),
          type: 'success',
          msDuration: 4000,
          class: '',
        });

        this.entriesForDelete = [];
        this.deleteMessage = false;
        this.requestExpenses({ loadingGlobal: true });
        this.draw();
      })
    );
  }

  /********************************************TOOLTIP************************************************** */
  showToolTip = false;
  iconDialog = 'assets/icons/gl_title-information-outline.svg';
  toggleTooltip(evt) {
    this.showToolTip = evt;
    if (!this.showToolTip) {
      this.iconDialog = 'assets/icons/gl_title-information-outline.svg';
    } else {
      this.iconDialog = 'assets/icons/gl_information-clicked.svg';
    }
  }

  showDialog(): void {
    const isMobile = window.screen.width < 768;
    if (!isMobile) {
      this.showToolTip = !this.showToolTip;
      this.toggleTooltip(this.showToolTip);
    } else {
      const dialog = this.dialog.open(ModalMobileInfoComponent, {
        data: {
          html: 'expense.tooltip',
          buttonText: 'kanban.dialog.confirmButton',
        },
      });
    }
  }

  getModuleResources() {
    this.sub$.add(
      this.tutorialService
        .get('expenses')
        .pipe(
          finalize(() => {
            this.draw();
          })
        )
        .subscribe((res) => {
          this.academyLink = res?.academy;
        })
    );
  }

  public refresh() {
    return this.draw();
  }

  get isLowerThan1450px() {
    return window.innerWidth < 1450;
  }

  download(type: DownloadType) {
    switch (type) {
      case DownloadType.EXCEL:
        if (this.entriesForDelete?.length) {
          this.downloadExcelSelection();
        } else {
          this.downloadExcel();
        }
        break;
      case DownloadType.PDF:
        break;
      default:
        break;
    }
  }
}
