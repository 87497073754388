import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CreateExpenseComponent } from './create-expense.component';

@Injectable({ providedIn: 'root' })
export class CreateExpenseService {
  private refreshListSource = new Subject<boolean>();
  refreshList$ = this.refreshListSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(config?: MatDialogConfig) {
    const dialogRef = this.dialog.open(CreateExpenseComponent, config);

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res as boolean);
      })
    );
  }
}
