<roma-modal-template
  title="{{ 'general.preview' | translate }}"
  [showSuccessBtn]="false"
  [textCancel]="'Cerrar'"
  [showDefaultButtons]="false"
  [footerTemplate]="footerContainer"
  (cancel)="close()"
>
  <ng-container *ngIf="loading; else noLoading">
    <roma-loading></roma-loading>
  </ng-container>
  <ng-template #noLoading>
    <div class="container__header">
      <div class="container__header__image" *ngIf="hasFeatureSeeLogo$ | async">
        <img
          *ngIf="reportData?.clientLogo"
          [src]="
            (hasFeatureSeeLogo$ | async)
              ? reportData?.clientLogo
                ? companyLogo
                : ''
              : 'assets/images/company-default-image.svg'
          "
          width="100"
          height="100"
          alt=""
        />
      </div>
      <div class="container__header__options">
        <div class="container__subtitle">
          {{ reportData?.companyName }}
        </div>
        <div class="container__text">
          {{ reportData?.companyTradeName }}
        </div>
        <div class="container__text">
          {{ reportData?.companyCode }}
        </div>
        <div class="container__text">
          {{ reportData?.companyPhone }}
        </div>
        <div class="container__text">
          {{ reportData?.companyEmail }}
        </div>
      </div>
    </div>
    <div class="container__element" *ngIf="data.fields.client">
      <div class="container__subtitle">
        {{ 'general.final' | translate }}
      </div>
      <div class="container__text">
        {{
          reportData?.clientName
            ? reportData?.clientName
            : ('general.propertyNotAvailable' | translate)
        }}
      </div>

      <div class="container__text">
        {{ reportData?.clientFiscalName }}
      </div>

      <div class="container__text">
        {{ reportData?.clientEmail }}
      </div>

      <div class="container__text" *ngIf="data.fields.address">
        {{ reportData?.clientAddress }}
      </div>
    </div>
    <div class="container__body">
      <h2 class="container__title">
        {{ 'general.part-of-service' | translate }}
      </h2>

      <ng-container *ngIf="data.fields.title">
        <strong class="container__subtitle">
          {{ 'general.title' | translate }}
        </strong>

        <p class="container__text">
          {{
            reportData?.dealTitle
              ? reportData?.dealTitle
              : ('general.propertyNotAvailable' | translate)
          }}
        </p>
      </ng-container>
      <ng-container
        *ngIf="reportData?.dealDescription && data.fields.description"
      >
        <strong class="container__subtitle">
          {{ 'general.description' | translate }}
        </strong>
        <p class="container__text">
          {{
            reportData?.dealDescription
              ? reportData?.dealDescription
              : ('general.propertyNotAvailable' | translate)
          }}
        </p>
      </ng-container>

      <ng-container *ngIf="data.fields.client">
        <strong class="container__subtitle">
          {{ 'general.final' | translate }}
        </strong>
        <p class="container__text">
          {{
            reportData?.dealClient
              ? reportData?.dealClient
              : ('general.propertyNotAvailable' | translate)
          }}
        </p>
      </ng-container>

      <ng-container *ngIf="data.fields.project">
        <strong class="container__subtitle">{{
          'general.project' | translate
        }}</strong>
        <p class="container__text">
          {{
            reportData?.dealProject
              ? reportData?.dealProject
              : ('general.propertyNotAvailable' | translate)
          }}
        </p>
      </ng-container>

      <ng-container *ngIf="reportData?.dealAddress">
        <strong class="container__subtitle">{{
          'general.address' | translate
        }}</strong>
        <p class="container__text">
          {{
            reportData?.dealAddress
              ? reportData?.dealAddress
              : ('general.propertyNotAvailable' | translate)
          }}
        </p>
      </ng-container>

      <ng-container
        *ngIf="
          reportData?.dealAddressAdditional && data.fields.addressAdditional
        "
      >
        <strong class="container__subtitle">{{
          'general.address_aditional' | translate
        }}</strong>
        <p class="container__text">{{ reportData?.dealAddressAdditional }}</p>
      </ng-container>

      <ng-container *ngIf="reportData?.dealDateWithoutFormat !== null">
        <div class="container-part-item">
          <strong class="container__subtitle">{{
            'general.date' | translate
          }}</strong>
          <p class="container__text">
            {{
              reportData?.dealDateWithoutFormat
                ? (reportData?.dealDateWithoutFormat
                  | date: company_date_format)
                : ('general.propertyNotAvailable' | translate)
            }}
          </p>
        </div>
      </ng-container>

      <ng-container *ngIf="reportData?.dealEquipment && data.fields.equipment">
        <strong class="container__subtitle">{{
          'general.equipment' | translate
        }}</strong>
        <p class="container__text">{{ reportData?.dealEquipment }}</p>
      </ng-container>

      <ng-container
        *ngIf="
          data.fields.customProperties && reportData?.customProperties.length
        "
      >
        <div *ngFor="let property of reportData?.customProperties">
          <div *ngIf="property.value !== ''">
            <strong class="container__subtitle">{{ property.title }}</strong>
            <p
              *ngIf="
                property.value === 'true' || property.value === 'false';
                else showValue
              "
              class="container__text"
            >
              {{
                property.value === 'true'
                  ? ('general.yes' | translate)
                  : ('general.no' | translate)
              }}
            </p>

            <ng-template #showValue>
              <p class="container__text">
                {{ property.value ? property.value : '' }}
              </p>
            </ng-template>
          </div>
        </div>
      </ng-container>
      <!-- <ng-container *ngIf="reportData?.customGroupPropertyName">
      <strong class="container__subtitle"> Formulario </strong>
      <p class="container__text">{{ reportData?.customGroupPropertyName }}</p>
    </ng-container> -->

      <ng-container
        *ngIf="
          reportData?.customGroupProperties.length &&
          data.fields.customGroupProperties
        "
      >
        <div *ngFor="let property of reportData?.customGroupProperties">
          <strong class="container__subtitle">{{ property.title }}</strong>
          <p
            *ngIf="
              property.value === 'true' || property.value === 'false';
              else showValueGroup
            "
            class="container__text"
          >
            {{
              property.value === 'true'
                ? ('general.yes' | translate)
                : ('general.no' | translate)
            }}
          </p>

          <ng-template #showValueGroup>
            <p class="container__text">
              {{ property.value ? property.value : '' }}
            </p>
          </ng-template>
        </div>
      </ng-container>

      <ng-container *ngIf="reportData?.partOfServiceImages?.length">
        <h2 class="container__title">{{ 'general.photos' | translate }}</h2>

        <div class="container__images">
          <img
            *ngFor="let image of reportData?.partOfServiceImages"
            [src]="image"
            alt=""
            width="75"
            height="75"
            class="container__images__item"
          />
        </div>
      </ng-container>

      <ng-container
        *ngIf="reportData?.partOfServiceDescription && data.fields.description"
      >
        <h2 class="container__title">
          {{ 'general.desc-of-part' | translate }}
        </h2>

        <div class="container__text">
          {{ reportData?.partOfServiceDescription }}
        </div>
      </ng-container>

      <div
        class="container__signatures"
        *ngIf="
          reportData?.partOfServiceSignatureClient && data.fields.description
        "
      >
        <h2 class="container__title">
          {{ 'generateDocument.signature' | translate }}
        </h2>
        <div class="container__signatures__item">
          <img
            [src]="reportData?.partOfServiceSignatureClient"
            width="150"
            height="150"
            class="container__signatures__item__image"
          />
          <div class="container__text">
            {{ 'generateDocument.client' | translate }}
          </div>
        </div>
        <div
          class="container__signatures__item"
          *ngIf="reportData?.partOfServiceSignatureCompany"
        >
          <img
            [src]="reportData?.partOfServiceSignatureCompany"
            width="150"
            height="150"
            class="container__signatures__item__image"
          />
          <div class="container__text">
            {{ 'generateDocument.company' | translate }}
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #footerContainer>
    <div
      *ngIf="showDisclaimer && !(hasFeatureSeeLogo$ | async) && !charginTrial"
      class="container-disclaimer"
    >
      <div class="roma-disclaimer dark">
        <div class="icon top circle blue">
          <img src="assets/icons/star.svg" alt="Icon disclaimer" />
        </div>
        <div class="body">
          <div
            [innerHTML]="'pro.deal.disclaimer.description' | translate"
          ></div>

          <button
            *ngIf="!trialPeriodUsed"
            romaProFeature
            [feature]="featureRef.Deal.SeeLogo"
            class="roma-button custom-btn-save"
          >
            {{ 'pro.deal.disclaimer.successText' | translate }}
          </button>
          <button
            *ngIf="trialPeriodUsed"
            romaProFeature
            [feature]="featureRef.Deal.SeeLogo"
            class="roma-button custom-btn-save"
          >
            {{ 'pro.deal.disclaimer.trialUsed' | translate }}
          </button>
        </div>

        <div class="options">
          <div class="icon size-24" (click)="showDisclaimer = false">
            <img src="assets/icons/gl_close_white.svg" alt="Icon close" />
          </div>
        </div>
      </div>

      <div
        *ngIf="!(resize$ | async)?.payload?.mobile"
        class="container-disclaimer-footer"
      >
        <button
          class="roma-button outline outline-gray dark-text"
          (click)="close()"
        >
          {{ 'billing.create.cancel' | translate }}
        </button>
      </div>
    </div>
  </ng-template>

  <strong>{{ reportData?.partOfServiceDate }}</strong>
</roma-modal-template>
