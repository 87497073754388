import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../services';
import { USER_LANG } from '@tacliatech/types';

export enum Lang {
  En = 'en',
  Es = 'es',
}

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  constructor(private translate: TranslateService) {}

  private emitChangeLanguage(lang: Lang) {
    try {
      window.dispatchEvent(new CustomEvent('mf-language', { detail: lang }));
    } catch (error) {
      console.error('Error emitting language change', error);
    }
  }

  async switchLang(lang: Lang) {
    StorageService.SetItem(USER_LANG, lang);
    this.emitChangeLanguage(lang);
    try {
      await this.translate.use(lang).toPromise();
    } catch (error) {
      console.error('Error switching language', error);
    }
  }

  initializeTranslate() {
    const defaultLang = this.defaultLang();
    this.switchLang(defaultLang);
  }

  defaultLang(): Lang {
    const lang = localStorage.getItem(USER_LANG) as Lang;
    if (lang) {
      return lang;
    }

    const browserLang = navigator.language.split('-')[0].toLowerCase();
    const spanishRelatedLangs = ['es', 'gl', 'eu', 'ca']; // Spanish and regional languages
    return spanishRelatedLangs.includes(browserLang) ? Lang.Es : Lang.En;
  }
}
