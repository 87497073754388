/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  EmailCustomValidator,
  FormatEmailValidator,
} from '@web-frontend/shared/validators/email';
import {
  FeatureService,
  StorageService,
  UserService,
  AuthService,
  CustomerService,
} from '@web-frontend/shared/services';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  COMPANY_ID_KEY,
  IGroupModules,
  ISector,
  Company,
  countryCodesEN,
  countryCodesES,
  Lang,
  COMPANY_DATA,
  USER_DATA,
  USER_ID_KEY,
} from '@tacliatech/types';
import { RmSelect } from '@web-frontend/shared/components/globals/rm-select/rm-select.types';
import { Observable, Subscription, timer } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ClientRegisterService } from '@web-frontend/shared/services/client-register/client-register.service';
import { I18nService } from '@web-frontend/shared/i18n';
import {
  CompanyModuleService,
  CompanyService,
} from '@web-frontend/shared/services/company';

import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { RecaptchaService } from '@web-frontend/core/recaptcha/recaptcha.service';
import { FeatureFlagsService } from '@web-frontend/shared/services/feature-flags/feature-flags.service';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';

import AmplitudeEvents from 'src/types/amplitude.enum';
import { CampaignInterface } from '../../../../../types-legacy/lib/interfaces/campaign.interface';
import CampaignManager from '../../../../shared/services/campaign/campaignManager.service';
import { Browser } from '@capacitor/browser';
import { authRoutes } from '../../auth-routing.module';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Component({
  selector: 'roma-new-register',
  templateUrl: './new-register.component.html',
  styleUrls: ['./new-register.component.scss'],
})
export class NewRegisterComponent implements OnInit {
  showPassword = false;
  showCountry = false;
  errorCountry = false;
  selectedPrefix = '';
  selectedOldPrefix = '';
  filter = /^([\w+\\-]+(?:\.[\w+\\-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  countryCodes = null;
  categories: RmSelect.Items = [];
  sectors: ISector[];
  sectors$: Observable<ISector[]> | null = null;
  categories$: Observable<any[]> | null = null;
  defaultCategory = {
    id: 'category.allCategories',
    name: 'register.sector.category.allCategories',
    nameValue: 'Todas las categorías',
  };
  selectedCategory: RmSelect.Items = [];
  category: RmSelect.Item;
  isLoadingCategories = false;
  selectedSector: ISector;
  readonly OTHER_SECTOR = 'sector.other-sector';
  searchTerm = '';
  sectorGestionInterna: IGroupModules;
  sectorVentas: IGroupModules;
  sectorGastos: IGroupModules;
  flightRequest = false;
  userDataRegister = null;
  timezone = null;
  isMobile = false;
  module = 'b';
  fromLogin = false;
  extrasLogin = null;

  form = new FormGroup({
    email: new FormControl('', {
      validators: [Validators.required, FormatEmailValidator()],
      asyncValidators: [
        EmailCustomValidator.checkIfIsRepeat(this.featureService),
        EmailCustomValidator.checkEmailDomain(this.featureService),
      ],
      updateOn: 'blur',
    }),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(50),
    ]),
    phone: new FormGroup({
      prefix: new FormControl('', [Validators.required]),
      number: new FormControl('', [Validators.required]),
    }),
    country: new FormControl('', Validators.required),
  });

  companyCategory = new FormGroup({
    category: new FormControl(''),
    search: new FormControl(''),
    sector: new FormControl('', Validators.required),
  });

  companyFunctions = new FormGroup({
    tool: new FormControl('', Validators.required),
  });

  private sub$ = new Subscription();
  activeStep: 'ZERO' | 'ONE' | 'TWO' | 'FINAL' | 'UTM' = 'ZERO';
  itemsStepTWo = ['tracking', 'bill', 'booking', 'deals', 'tasks', 'expense'];
  selectSector = null;
  scrollActive = false;
  initialPhoneValue: { pre: string; number: string } = { pre: '', number: '' };
  registerAskForPhone = false;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    const innerWidth = window.innerWidth;
    const innerHeight = window.innerHeight;
    this.isMobile = innerWidth <= 768 || innerHeight <= 600;
  }

  @HostListener('click', ['$event'])
  onClickDiv(evt: MouseEvent): void {
    const targetDiv = evt.target as HTMLElement;

    const id = targetDiv.id;
    if (id != 'inputCountry' && this.showCountry) {
      this.showCountry = false;

      this.selectedPrefix = this.selectedOldPrefix;
    }
  }

  constructor(
    private featureService: FeatureService,
    private activeRoute: ActivatedRoute,
    private HttpClient: HttpClient,
    private registerService: ClientRegisterService,
    private translate: TranslateService,
    private i18nService: I18nService,
    private changeDetectionRef: ChangeDetectorRef,
    private userService: UserService,
    private companyModuleService: CompanyModuleService,
    private authService: AuthService,
    private customerService: CustomerService,
    private router: Router,
    private companyService: CompanyService,

    private amplitudeService: AmplitudeService,
    private brazeService: BrazeService,
    private permissionService: PermissionService,
    private recaptchaService: RecaptchaService,
    private featureFlags: FeatureFlagsService,
    private analyticsService: AnalyticsService,
    private campaignManager: CampaignManager
  ) {
    this.activeStep = 'ZERO';
    this.onResize();
    this.setCountryArr();
    const localRegister = localStorage.getItem('register');
    const customer = localStorage.getItem('USER_ID');
    if (customer) {
      this.analyticsService.identifyUser(['braze']);
      this.fromLogin = true;
      this.extrasLogin = {
        id: customer,
        register: localRegister,
      };
      this.getCategories();
      this.getRegisterSectors();
      this.getUserData();
      if (localRegister) {
        if (localRegister === 'step1') {
          this.activeStep = 'ONE';
        } else if (localRegister === 'step2') {
          this.activeStep = 'TWO';
        }

        if (this.campaignManager.getCampaign()) {
          this.activeStep = 'UTM';
          this.onStart();
        }
      }
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  get phoneErrors() {
    const pre = this.f.phone.get('prefix');
    const number = this.f.phone.get('number');
    return (pre.errors || number.errors) && (pre.touched || number.touched);
  }

  get actualSector() {
    return this.companyCategory.get('sector').value;
  }

  get screenHeight(): string {
    const height = window.innerHeight;
    if (this.isMobile) {
      return this.scrollActive ? height - 165 + 'px' : height - 255 + 'px';
    } else {
      return this.scrollActive ? '390px' : '300px';
    }
  }

  checkScroll(e): void {
    if (e.currentTarget.scrollTop > 65) {
      this.scrollActive =
        e.currentTarget.scrollTop > e.currentTarget.scrollTop ? false : true;
    } else {
      this.scrollActive = false;
    }
  }

  phoneChange(evt: {
    pre: string;
    number: number;
    countryCode?: string;
    countryName?: string;
  }): void {
    const phoneGroup = this.form.get('phone') as FormGroup;

    phoneGroup.patchValue({
      prefix: evt.pre,
      number: evt.number,
    });
  }

  onPrefixChange($event): void {
    this.setCountry($event);
  }

  getUserData(): void {
    const registerData = StorageService.GetItem('userInfo', true);
    const newRegister = {
      createUser: {
        _id: registerData._id,
        email: registerData.email,
        register: registerData.register,
        company: registerData.company._id,
      },
      createdCompany: registerData.company,
      createdCustomer: [registerData.company?.customer],
    };
    newRegister.createUser.company = registerData.company?._id;
    this.userDataRegister = newRegister;

    if (this.activeStep === 'TWO') {
      this.customerService
        .findOne(this.userDataRegister?.createdCustomer[0]?._id)
        .subscribe((res) => {
          if (!res.sector_id) {
            //if we don't find sector_id go to take sector
            this.activeStep = 'ONE';
            return;
          }
          this.companyCategory.patchValue({ sector: res.sector_id });
          this.selectedSector = this.sectors.find(
            (it) => it._id === res.sector_id
          );

          if (!this.selectedSector) {
            this.activeStep = 'ONE';
            return;
          }
        });
    }
  }

  getRegisterSectors(): void {
    this.sectors$ = this.registerService.getSectors().pipe(shareReplay());
    this.registerService
      .getSectors()
      .pipe(shareReplay())
      .subscribe((res) => {
        this.sectors = res;
        this.draw();
      });
  }

  ngOnInit(): void {
    this.permissionService.resetCheckTrialCache();
    this.companyCategory.get('search').valueChanges.subscribe((val) => {
      this.searchTerm = val;

      this.searchSector();
    });

    this.selectedCategory = [
      {
        id: this.defaultCategory.id,
        title: this.defaultCategory.name,
        value: this.defaultCategory.id,
      },
    ];
  }

  setCountryArr(): void {
    const LANG = StorageService.GetItem('USER_LANG') as Lang;

    this.countryCodes = LANG === 'en' ? countryCodesEN : countryCodesES;
    this.countryCodes = this.countryCodes.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  applySimpleModuleUpdate(feature: string): void {
    const data: any = {
      sector: null,
      idModule: feature,
      active: true,
    };
    this.sub$.add(
      this.companyModuleService.updateCompanyModule(data).subscribe()
    );
  }

  applyBulkModuleUpdate(feature: Company.IdModuleSector): void {
    const data: Company.PutUpdatStateModules = {
      category: feature,
      active: true,
    };
    this.sub$.add(
      this.companyModuleService.updateCompanyModuleSector(data).subscribe()
    );
  }

  onStart(): void {
    if (this.activeStep === 'ONE') {
      this.flightRequest = true;
      this.getSectorData();
      this.sub$.add(
        this.userService
          .updateOne(this.userDataRegister.createUser._id, {
            register: 'step2',
            company: this.userDataRegister.createUser.company,
          })
          .subscribe(() => {
            this.activeStep = 'TWO';
            localStorage.setItem('register', 'step2');
            this.flightRequest = false;
          })
      );
    } else if (this.activeStep === 'TWO') {
      const sectorActions = {
        tracking: () => {
          pathUrl = '/admin/clock-hours';
          stChoice = Company.stChoiceEnum.Timetracking_choice;
          this.brazeService.setCustomAttributes({
            name: 'first_choice',
            value: stChoice,
          });
          this.analyticsService.trackEvent({
            sources: ['amplitude', 'braze'],
            eventName: AmplitudeEvents.register_toolTimeTracking_start,
          });
          this.applySimpleModuleUpdate('CLOCK_HOURS');
        },
        bill: () => {
          pathUrl = '/admin/budgets?type=BILL';
          stChoice = Company.stChoiceEnum.Invoicing_choice;
          this.brazeService.setCustomAttributes({
            name: 'first_choice',
            value: stChoice,
          });

          this.analyticsService.trackEvent({
            sources: ['amplitude', 'braze'],
            eventName: AmplitudeEvents.register_toolBill_start,
          });
          this.applyBulkModuleUpdate(Company.IdModuleSector.Sales);
        },
        deals: () => {
          pathUrl = '/admin/deals';
          stChoice = Company.stChoiceEnum.Clients_services_choice;
          this.brazeService.setCustomAttributes({
            name: 'first_choice',
            value: stChoice,
          });
          this.analyticsService.trackEvent({
            sources: ['amplitude', 'braze'],
            eventName: AmplitudeEvents.register_toolService_start,
          });
          this.applyBulkModuleUpdate(Company.IdModuleSector.Sales);
        },
        booking: () => {
          pathUrl = '/admin/online-booking';
          stChoice = Company.stChoiceEnum.OnlineBooking_choice;
          this.brazeService.setCustomAttributes({
            name: 'first_choice',
            value: stChoice,
          });
          this.analyticsService.trackEvent({
            sources: ['amplitude', 'braze'],
            eventName: AmplitudeEvents.register_toolOnlineBooking_start,
          });
          this.applyBulkModuleUpdate(Company.IdModuleSector.Sales);
        },
        tasks: () => {
          pathUrl = '/admin/activities';
          stChoice = Company.stChoiceEnum.Tasks_choice;
          this.brazeService.setCustomAttributes({
            name: 'first_choice',
            value: stChoice,
          });
          this.analyticsService.trackEvent({
            sources: ['amplitude', 'braze'],
            eventName: AmplitudeEvents.register_toolTasks_start,
          });
          this.applySimpleModuleUpdate('ACTIVITIES');
        },
        expense: () => {
          pathUrl = '/admin/expenses';
          stChoice = Company.stChoiceEnum.Expenses_choice;
          this.brazeService.setCustomAttributes({
            name: 'first_choice',
            value: stChoice,
          });
          this.analyticsService.trackEvent({
            sources: ['amplitude', 'braze'],
            eventName: AmplitudeEvents.register_toolExpenses_start,
          });
          this.applyBulkModuleUpdate(Company.IdModuleSector.Expenses);
        },
      };

      let pathUrl = '';
      let stChoice: Company.stChoiceEnum;

      if (sectorActions[this.selectSector]) {
        sectorActions[this.selectSector]();
      }

      this.activeStep = 'FINAL';

      this.sub$.add(
        this.companyService
          .findOneAndUpdate(StorageService.CompanyId, {
            customerId: StorageService.CustomerId,
            stChoice,
            copyTranslate: this.module,
          })
          .pipe(
            switchMap(() =>
              this.userService.updateOne(this.userDataRegister.createUser._id, {
                register: 'complete',
                company: this.userDataRegister.createUser.company,
              })
            ),
            tap(() => this.finishOnboardingStorage()),
            switchMap(() => timer(2000))
          )
          .subscribe(() => {
            this.router.navigateByUrl(pathUrl).then(() => {});
          })
      );
    } else if (this.activeStep === 'UTM') {
      this.flightRequest = true;
      this.getRegisterSectors();
      const {
        firstChoice,
        moduleT,
        events,
        url,
      }: CampaignInterface = this.campaignManager.getCampaign();

      this.brazeService.setCustomAttributes({
        name: 'first_choice',
        value: firstChoice,
      });

      events.forEach((event) => {
        this.analyticsService.trackEvent({
          sources: ['amplitude', 'braze'],
          eventName: event,
        });
      });
      this.module = moduleT;
      switch (moduleT) {
        case Company.IdModuleSector.Sales:
        case Company.IdModuleSector.Expenses:
          this.applyBulkModuleUpdate(moduleT);
          break;
        default:
          this.applySimpleModuleUpdate(moduleT);
      }
      this.sub$.add(
        this.companyService
          .findOneAndUpdate(StorageService.CompanyId, {
            customerId: StorageService.CustomerId,
            stChoice: firstChoice,
          })
          .pipe(
            switchMap(() =>
              this.userService.updateOne(this.userDataRegister.createUser._id, {
                register: 'complete',
                company: this.userDataRegister.createUser.company,
              })
            ),
            tap(() => this.finishOnboardingStorage()),
            switchMap(() => timer(2000))
          )
          .subscribe(() => {
            this.router.navigateByUrl(`${url}`).then(() => {});
          })
      );
    }
  }

  openModalCountry(): void {
    if (!this.showCountry) {
      this.showCountry = true;
    }
  }

  cancelOnboarding(): void {
    this.userService
      .updateOne(this.userDataRegister.createUser._id, {
        register: 'complete',
        company: this.userDataRegister.createUser.company,
      })
      .subscribe(() => {
        this.analyticsService.trackEvent({
          sources: ['amplitude', 'braze'],
          eventName:
            this.activeStep === 'ONE'
              ? AmplitudeEvents.register_sector_exit
              : AmplitudeEvents.register_tools_exit,
        });

        if (!this.companyCategory.get('sector').value) {
          this.sectors.map((data: ISector) => {
            if (data.nameRef === this.OTHER_SECTOR) {
              data.name = 'None';
              this.updateCustomerInfo(data);
            }
          });
        }

        this.companyService
          .findOneAndUpdate(StorageService.CompanyId, {
            customerId: StorageService.CustomerId,
            stChoice: 'None',
          })
          .toPromise();
        this.finishOnboardingStorage();
        this.router.navigateByUrl('/');
      });
  }

  onSubmit(): void {
    const countryOk = this.countryCodes.filter((e) =>
      e.code.includes(this.form.value.country)
    );

    if (this.showCountry || !countryOk) {
      this.showCountry = false;
      this.selectedPrefix = '';
      this.searchCountry('');
      return;
    }

    if (!this.form.valid) {
      if (this.form.value.email === '') {
        this.sendEvent('register_save_mailEmpty', 'amplitude');
      }
      if (this.form.value.password === '') {
        this.sendEvent('register_save_passwordEmpty', 'amplitude');
      }
      if (
        this.form.value.country === '' ||
        this.form.value.country === null ||
        this.form.value.country === undefined
      ) {
        this.sendEvent('register_save_countryEmpty', 'amplitude');
      }
      this.form.markAllAsTouched();
    } else {
      this.flightRequest = true;
      this.getCategories();

      this.sectors$ = this.registerService.getSectors().pipe(shareReplay());

      this.changeCategory(this.selectedCategory);

      const data = this.form.value;
      data.utm_data = this.campaignManager.getUtm();
      if (
        typeof data.utm_data === 'object' &&
        Object.keys(data.utm_data).length !== 0
      ) {
        if (
          Object.keys(data.utm_data).every((it) => {
            return data.utm_data[it] === undefined;
          })
        ) {
          data.utm_data = null;
        }
      } else {
        data.utm_data = null;
      }
      data.timezone = this.timezone;
      this.recaptchaService
        .execute('register')
        .then((token) => {
          data.token = token;
          this.sub$.add(
            this.userService
              .newRegister(data)
              .pipe(
                tap((response: any) => {
                  if (response) {
                    this.userDataRegister = response;

                    StorageService.SetItem(
                      COMPANY_ID_KEY,
                      this.userDataRegister.createdCompany._id
                    );

                    StorageService.SetItem(COMPANY_DATA, {
                      companyId: this.userDataRegister.createdCompany._id,
                      customerId: this.userDataRegister.createdCompany.customer,
                      created_at: this.userDataRegister.createdCompany
                        .created_at,
                    });

                    localStorage.setItem(
                      USER_DATA,
                      JSON.stringify(this.userDataRegister.createUser)
                    );

                    localStorage.setItem(
                      USER_ID_KEY,
                      this.userDataRegister.createUser._id
                    );
                    try {
                      window.dataLayer.push({
                        event: 'GTM_form_submit',
                        registrationStatus: 'success',
                      });
                    } catch (error) {
                      console.error('Error pushing data to dataLayer:', error);
                    }

                    if (
                      !this.userDataRegister?.createUser?.register ||
                      this.userDataRegister?.createUser?.register === 'step1'
                    ) {
                      localStorage.setItem('register', 'step1');
                    } else {
                      localStorage.setItem('register', 'step2');
                    }
                  }
                }),
                switchMap(() =>
                  this.authService.login({
                    username: this.form.value.email,
                    password: this.form.value.password,
                  })
                )
              )
              .subscribe(
                async () => {
                  this.extrasLogin = {
                    id: this.userDataRegister.createUser._id,
                  };
                  this.getUserData();
                  this.companyModuleService.init();
                  await this.permissionService.init();
                  this.analyticsService.identifyUser(['amplitude', 'braze']);
                  if (
                    !this.userDataRegister?.createUser?.register ||
                    this.userDataRegister?.createUser?.register === 'step1'
                  ) {
                    if (this.campaignManager.getCampaign()) {
                      this.activeStep = 'UTM';
                      this.onStart();
                    } else {
                      this.activeStep = 'ONE';
                    }
                  } else {
                    this.activeStep = 'TWO';
                  }
                  this.flightRequest = false;
                },
                (err: HttpErrorResponse) => {
                  console.error(err);
                  this.errorCountry = true;
                  this.flightRequest = false;
                }
              )
          );
        })
        .catch((err) => {
          console.error(err);
          this.errorCountry = true;
          this.flightRequest = false;
        });
    }
  }

  searchCountry($event: any): void {
    const value = $event?.target?.value
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    if (value === null || value === '' || $event === '') {
      this.setCountryArr();
      this.form.value.country = null;
      this.errorCountry = true;
      return;
    }
    this.showCountry = true;
    this.countryCodes = this.countryCodes.filter((e) => !e.filter);

    const filteredCountries = this.countryCodes.filter((e) =>
      e.name
        ?.toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(value)
    );

    this.countryCodes = this.countryCodes.filter(
      (e) => !e.name?.toLocaleLowerCase().includes(value)
    );
    this.countryCodes.splice(0, 0, ...filteredCountries);
    const set = new Set(this.countryCodes);
    this.countryCodes = Array.from(set);

    if (!this.countryCodes.length) {
      this.errorCountry = true;
      this.form.value.country = null;
      this.countryCodes.push({
        name: this.translate.instant('general.noMatches'),
        dial_code: '',
        code: '',
      });
    }
  }

  setCountry($event?: { name: string; code: string }): void {
    this.showCountry = false;
    this.errorCountry = false;
    this.selectedPrefix = $event.name;
    this.selectedOldPrefix = $event.name;
    if (this.form.get('country').value !== $event.code) {
      this.form.patchValue({ country: $event.code });
    }
    const selectedIndex = this.countryCodes.findIndex(
      (country) => country.code === $event.code
    );

    if (selectedIndex !== -1) {
      const selectedCountry = this.countryCodes.splice(selectedIndex, 1)[0];
      this.countryCodes.unshift(selectedCountry);
    }
  }

  goClick($event): void {
    const value = $event.srcElement.attributes?.class?.value;
    if (!value?.includes('arrCountry')) {
      this.showCountry = false;
    }
  }

  private getCategories(): void {
    this.isLoadingCategories = true;
    this.sub$.add(
      this.registerService
        .getCategories()
        .pipe(
          map((categories) => [
            {
              _id: this.defaultCategory.id,
              value: this.defaultCategory.nameValue,
              title: this.translate.instant(
                'register.sector.' + this.defaultCategory.id
              ),
            },
            ...categories.map((category) => ({
              title: this.translate.instant(
                'register.sector.' + category.nameRef
              ),
              value: category.name,
              id: category._id,
            })),
          ])
        )
        .subscribe((categories: any) => {
          this.categories = categories;
          this.isLoadingCategories = false;
        })
    );
  }

  private searchSector() {
    this.sub$.add(
      this.sectors$
        .pipe(
          tap((sector) => {
            sector.sort((a, b) => a.name.localeCompare(b.name));
          }),
          map((sector) => {
            const filteredSector = sector
              .filter((data): any => {
                if (
                  this.category?.id &&
                  this.category.id != this.defaultCategory.id
                ) {
                  return data.categoryId === this.category.id;
                } else if (data.categoryId || data.categoryId !== undefined) {
                  return data;
                }
              })
              .filter((data) => {
                const translated: string = this.translate.instant(
                  `register.${data.nameRef}`
                );
                return translated
                  .toLocaleLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .includes(
                    this.searchTerm
                      .toLocaleLowerCase()
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                  );
              });

            filteredSector.push(
              sector.find((s) => s.nameRef === this.OTHER_SECTOR)
            );
            this.draw();
            return filteredSector;
          })
        )
        .subscribe((res) => {
          if (this.category && this.category.id != this.defaultCategory.id) {
            res.length > 0 &&
            (res[0].categoryId === this.category.id ||
              res[0].nameRef === this.OTHER_SECTOR)
              ? (this.sectors = res)
              : (this.sectors = []);
          } else {
            res.length > 0 ? (this.sectors = res) : (this.sectors = []);
          }
          this.draw();
        })
    );
  }

  private getSectorData() {
    this.flightRequest = true;
    this.sub$.add(
      this.sectors$
        .pipe(
          map((sector) => {
            const defaultLang = this.i18nService.defaultLang();
            this.selectedSector = sector.find(
              (s) => s._id === this.companyCategory.get('sector').value
            );
            this.module = this.selectedSector.module;

            this.category = this.categories.find(
              (c) => c.id == this.selectedSector?.categoryId
            );

            this.brazeService.setCustomAttributes({
              name: 'company_sector',
              value: this.selectedSector?.name || '',
            });

            this.brazeService.setCustomAttributes({
              name: 'company_type',
              value: this.category?.value || '',
            });

            this.selectedSector?.config?.map((data) => {
              if (sector.length === 0) {
                data.modules.map((module) => (module.disabled = true));
              }
              if (data.nameValue === 'Gestion interna') {
                this.sectorGestionInterna = {
                  ...data,
                  disabled:
                    sector.length > 0 ? this.setConfigState(data) : true,
                };
              } else if (data.nameValue === 'Ventas') {
                const booking = data.modules[8];
                data.modules.splice(8, 1);
                data.modules.splice(5, 0, booking);

                this.sectorVentas = {
                  ...data,
                  disabled:
                    sector.length > 0 ? this.setConfigState(data) : true,
                };
              } else if (data.nameValue === 'Gastos') {
                this.sectorGastos = {
                  ...data,
                  disabled:
                    sector.length > 0 ? this.setConfigState(data) : true,
                };
              }
            });

            const filteredS: any = sector.filter((data: ISector) => {
              if (data._id === this.companyCategory.get('sector').value) {
                this.updateCustomerInfo(data);
              }
            });

            sector.map((s) => {
              !s.categoryId || s.categoryId === undefined
                ? (s.categoryId = this.defaultCategory.id)
                : false;
            });

            return filteredS;
          })
        )
        .subscribe((res) => {})
    );
  }

  setConfigState(data): boolean {
    return data.modules.every((m) => m.disabled);
  }

  setDefaultCategory(): void {
    const category = {
      id: this.defaultCategory.id,
      title: this.defaultCategory.name,
      value: this.defaultCategory.nameValue,
    };
    this.category = category;
    this.selectedCategory = [category];
  }

  private updateCustomerInfo(data: ISector) {
    const customer = this.userDataRegister?.createdCustomer;
    const customerId = Array.isArray(customer) ? customer[0]._id : customer._id;
    this.customerService
      .updateOne(customerId, {
        sector_id: data._id.toString(),
        sector: data.name,
        company_type: data.name,
      })
      .subscribe(() => {
        this.getCustomerInfo(customerId);
      });
  }

  private getCustomerInfo(customerId: string) {
    this.customerService
      .findOne(customerId)
      .pipe(
        tap((res) => {
          const customerData = {
            company_size: res.company_size,
            company_type: res.company_type,
            channel: res.description,
            sector: res?.sector,
            business_type: res.business_type,
            sector_id: res?.sector_id ? res?.sector_id : null,
          };
          localStorage.setItem('customerData', JSON.stringify(customerData));
        })
      )
      .subscribe();
  }

  changeCategory(event): void {
    if (event.length > 0) {
      if (event[0].id === undefined) {
        this.setDefaultCategory();
      } else {
        this.category = event[0];
        this.sendEventCategory(event[0].value);
      }
    } else {
      this.setDefaultCategory();
    }

    this.searchSector();
  }

  openSelectCategory(): void {
    this.sendEvent('register_sector_category', 'amplitude');
  }

  _searchClicked = false;
  onSearchClick($event): void {
    this._searchClicked = true;
  }

  onSearchKeyPress($event): void {
    if (this._searchClicked) {
      this._searchClicked = false;
      this.sendEvent('register_sector_search', 'amplitude');
    }
  }

  sendEventCategory(category: string): void {
    switch (category) {
      case 'Comercios y establecimientos':
        this.sendEvent('register_sector_category_commerce', 'amplitude');
        break;
      case 'Educación y formación':
        this.sendEvent('register_sector_category_education', 'amplitude');
        break;
      case 'Fabricación y agricultura':
        this.sendEvent('register_sector_category_factory', 'amplitude');
        break;
      case 'Hostelería, ocio y turismo':
        this.sendEvent('register_sector_category_hostelry', 'amplitude');
        break;
      case 'Mantenimiento, instalaciones y construcciones':
        this.sendEvent('register_sector_category_maintenance', 'amplitude');
        break;
      case 'Salud y cuidado personal':
        this.sendEvent('register_sector_category_health', 'amplitude');
        break;
      case 'Servicios profesionales':
        this.sendEvent('register_sector_category_services', 'amplitude');
        break;
      case 'Transportes y logística':
        this.sendEvent('register_sector_category_transport', 'amplitude');
        break;
      default:
        this.sendEvent('register_sector_category_all', 'amplitude');
        break;
    }
  }

  clearSearch(): void {
    this.companyCategory.get('search').setValue('');
  }

  validateNextStep(): void {
    const selectedSector = this.sectors.find(
      (s) => s._id === this.companyCategory.get('sector').value
    );
    if (selectedSector) {
      this.analyticsService.trackEvent({
        sources: ['amplitude', 'braze'],
        eventName: AmplitudeEvents.register_sector_next,
        eventProperties: {
          sector: selectedSector.name,
        },
      });
    }
  }

  returnStep(step: string): void {
    this.activeStep = step as 'ZERO' | 'ONE' | 'TWO' | 'FINAL';
    this.sendEvent('register_tools_back', 'amplitude');
    this.sendEvent('register_tools_back', 'braze');

    if (step === 'ONE' && this.companyCategory.get('sector').value) {
      this.category = null;
      setTimeout(() => {
        const scroll = window.document.getElementById(
          'sectorContainer_' + this.companyCategory.get('sector').value
        ).offsetTop;

        document.getElementById('sectorCards').scrollTo(0, scroll - 400);
      }, 200);
    }
    if (step === 'ZERO') {
      this.authService.logout();
    }
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  goLogin() {
    this.sendEvent('register_loginStart', 'amplitude');
    this.sendEvent('register_loginStart', 'braze');
    this.router.navigate([authRoutes.login]);
  }

  sendEvent(event: string, platform: 'amplitude' | 'braze') {
    if (platform === 'amplitude') {
      this.amplitudeService.sendEvent({
        event: event,
      });
    } else if (platform === 'braze') {
      this.brazeService.sendEvent({
        event: event,
      });
    }
  }

  browserOpen(url: string) {
    return Browser.open({
      presentationStyle: 'popover',
      url,
    });
  }

  private finishOnboardingStorage() {
    StorageService.Remove('register');
    StorageService.Remove('userInfo');
  }
}
